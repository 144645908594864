import { useHttp } from './http.hook';

export const useLogUser = () => {
  const { request } = useHttp();

  return {
    postLog: async (partitionName, action) => {
      await request(`api/partition-log`, 'POST', {
        partitionName,
        action,
      });
    },
  };
};

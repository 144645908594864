import './index.css';
import React, { useEffect, useState } from 'react';

import DefaultLayout from '../../components/defaultLayout/DefaultLayout';
import AgentsTable from '../../components/AgentsTable/AgentsTable';
import { useHttp } from '../../hooks/http.hook';
import ForwarderContactsTable from '../../components/ForwarderContactsTable/ForwarderContactsTable';
import { useLogUser } from '../../hooks/useLogUser.hook';

// СТРАНИЦА ДЛЯ ЛИЧНОГО КАБИНЕТА ЭКСПЕДИТОРА
function ProfilePage() {
  const { request } = useHttp();
  const { postLog } = useLogUser();
  const [forwarderData, setForwarderData] = useState();
  const [agentsContacts, setAgentsContacts] = useState();
  const [forwId, setForwId] = useState();

  const fetchDataForwarder = async () => {
    try {
      const resData = await request(`api/forwarder/lk`);
      await setForwId(resData.id);

      setForwarderData(resData);
      const resDataAgentsContacts = await request(
        `api/agent-contact/by-forwarder/${resData.id}`
      );
      const newResDataAgentsContacts = resDataAgentsContacts?.map((item) => {
        return {
          ...item,
          key: item?.id,
        };
      });
      setAgentsContacts(newResDataAgentsContacts);
    } catch (error) {
      console.log('error-fetchDataForwarder >>>', error);
    }
  };

  const FetchData = async () => {
    await fetchDataForwarder();
    postLog('Личный кабинет (reslog)', 'вход в раздел Личный кабинет (reslog)');
  };

  useEffect(() => {
    FetchData();
  }, []);

  return (
    <>
      <DefaultLayout>
        <div className="ProfilePage__header">
          <div className="ProfilePage__profileInfo">
            <span className="ProfilePage__profileName">
              {forwarderData ? forwarderData?.name : 'нет данных'}
            </span>
          </div>
        </div>
        <ForwarderContactsTable
          forwarderContactsData={forwarderData?.forwarder_contacts}
          fetchDataTable={fetchDataForwarder}
          forwId={forwId}
        />
        <AgentsTable
          agentsData={agentsContacts}
          fetchDataTable={fetchDataForwarder}
          forwId={forwId}
        />
      </DefaultLayout>
    </>
  );
}

export default ProfilePage;

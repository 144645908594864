import React, { useEffect, useState } from 'react';
import { Table, Form, Button, Modal } from 'antd';
import './index.css';

import { useHttp } from '../../hooks/http.hook';
import getFiltersInColumn from '../../utils/filteredColumns';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth.hook';
import ModalOffer from '../modalOffer/ModalOffer';

import { DownloadOutlined } from '@ant-design/icons';
import DownloadButton from './DownloadButton';
import { useLogUser } from '../../hooks/useLogUser.hook';

const OrdersTable = () => {
  const history = useHistory();
  const { userId } = useAuth();
  const { loading, request } = useHttp();
  const { postLog } = useLogUser();

  const [data, setData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [extraData, setExtraData] = useState({});
  const [dataForSelect, setDataForSelect] = useState();

  const columns = [
    {
      title: 'Порт отправки',
      dataIndex: 'port',
      key: 'port',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'port'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.port) {
          return !record.port;
        } else {
          return record.port?.includes(value);
        }
      },
      sorter: (a, b) => a.port.localeCompare(b.port),
      render: (text, record) => {
        return !text ? 'данных нет' : text;
      },
    },
    {
      title: 'Средний вес',
      dataIndex: 'gw',
      key: 'gw',
      width: '8%',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(data, setSelectedKeys, selectedKeys, confirm, 'gw'),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.gw) {
          return !record.gw;
        } else {
          return record.gw === value;
        }
      },
      sorter: (a, b) => a.gw - b.gw,
      render: (text, record) => {
        return !text ? 'данных нет' : text;
      },
    },
    {
      title: 'Пункт назначения',
      dataIndex: 'destination',
      key: 'destination',
      width: '18%',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'destination'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.destination) {
          return !record.destination;
        } else {
          return record.destination?.includes(value);
        }
      },
      sorter: (a, b) => a.destination.localeCompare(b.destination),
      render: (text, record) => {
        return !text ? 'данных нет' : text;
      },
    },
    {
      title: '20HQ',
      dataIndex: 'containersCount20hq',
      key: 'containersCount20hq',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'containersCount20hq'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.containersCount20hq) {
          return !record.containersCount20hq;
        } else {
          return record.containersCount20hq === value;
        }
      },
      sorter: (a, b) => a.containersCount20hq - b.containersCount20hq,
      render: (text, record) => {
        return record.containersCount20hq;
      },
    },
    {
      title: '40HQ',
      dataIndex: 'containersCount40hq',
      key: 'containersCount40hq',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'containersCount40hq'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.containersCount40hq) {
          return !record.containersCount40hq;
        } else {
          return record.containersCount40hq === value;
        }
      },
      sorter: (a, b) => a.containersCount40hq - b.containersCount40hq,
      render: (text, record) => {
        return record.containersCount40hq;
      },
    },
    {
      title: 'Количество контейнеров',
      dataIndex: 'containersCount',
      key: 'containersCount',
      width: '150px',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'containersCount'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.containersCount) {
          return !record.containersCount;
        } else {
          return record.containersCount === value;
        }
      },
      sorter: (a, b) => a.containersCount - b.containersCount,
      render: (text, record) => {
        return !text ? 'данных нет' : text;
      },
    },
    {
      title: 'Кнт с аккумуляторами',
      dataIndex: 'containersCountBatteries',
      key: 'containersCountBatteries',
      width: '150px',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'containersCountBatteries'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.containersCountBatteries) {
          return !record.containersCountBatteries;
        } else {
          return record.containersCountBatteries === value;
        }
      },
      sorter: (a, b) => a.containersCountBatteries - b.containersCountBatteries,
      render: (text, record) => {
        return !text ? (
          ''
        ) : (
          <div>
            <span>{text}</span>
            <DownloadButton record={record} docType={'batteries'} />
          </div>
        );
      },
    },
    {
      title: 'Кнт с маслом',
      dataIndex: 'containersCountOil',
      key: 'containersCountOil',
      width: '150px',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'containersCountOil'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.containersCountOil) {
          return !record.containersCountOil;
        } else {
          return record.containersCountOil === value;
        }
      },
      sorter: (a, b) => a.containersCountOil - b.containersCountOil,
      render: (text, record) => {
        return !text ? (
          ''
        ) : (
          <div>
            <span>{text}</span>
            <DownloadButton record={record} docType={'oil'} />
          </div>
        );
      },
    },
    {
      title: 'Дата готовности груза',
      dataIndex: 'goodsReadinessDate',
      key: 'goodsReadinessDate',
      width: '150px',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
        getFiltersInColumn(
          data,
          setSelectedKeys,
          selectedKeys,
          confirm,
          'goodsReadinessDate'
        ),
      onFilter: (value, record) => {
        if (value === 'нет данных' && !record.goodsReadinessDate) {
          return !record.goodsReadinessDate;
        } else {
          return record.goodsReadinessDate?.includes(value);
        }
      },
      sorter: (a, b) =>
        a?.goodsReadinessDate &&
        a.goodsReadinessDate.localeCompare(
          b?.goodsReadinessDate && b.goodsReadinessDate
        ),
      render: (text, record) => {
        return !text ? 'данных нет' : text;
      },
    },
    {
      title: 'Действие',
      dataIndex: 'sendOffer',
      key: 'sendOffer',
      width: '50px',
      render: (text, record) => {
        return (
          <button
            className="btnSendOffer"
            onClick={() => {
              let containerType = null;
              if (
                record.containersCount40hq > 0 &&
                record.containersCount20hq > 0
              ) {
                containerType = null;
              } else if (
                record.containersCount40hq > 0 &&
                record.containersCount20hq === 0
              ) {
                containerType = '40HQ';
              } else if (
                record.containersCount40hq === 0 &&
                record.containersCount20hq > 0
              ) {
                containerType = '20HQ ';
              }

              setExtraData({
                port: record.port,
                destination: record.destination,
                offerData: {
                  offerGw: record.gw,
                  offerContainers: record.containersCount,
                  offerRealContainers: record.realContainersCount,
                  containerType,
                },
              });
              showModal(true);
              postLog(
                'Заявки (reslog)',
                'Открытие модального окна "Отправить предложение"'
              );
            }}
          >
            Отправить предложение
          </button>
        );
      },
    },
  ];

  const onFinish = async (values) => {};

  const showModal = (value) => {
    setIsModalVisible(value);
  };

  const handlerMyOffers = () => {
    history.push(`/user-offers/${userId}`);
  };

  // const optionsSelection = {
  //   selectedRowKeys: selectedRows,
  //   fixed: 'left',
  //   onChange: (keys) => {
  //     setSelectedRows(keys);
  //   },
  // };

  const fetchDataTable = async () => {
    try {
      const resData = await request('api/offer');
      const newResData = resData.rows.map((item) => {
        return {
          ...item,
          key: item?.id,
        };
      });
      setData(newResData);
    } catch (error) {
      console.log('error-getDataOrdersTable >>>', error);
    }
  };

  const fetchDataSelect = async () => {
    try {
      const resDataSelect = await request('api/offer/data-for-select');
      setDataForSelect({
        termsOfDelivery: resDataSelect.termsOfDelivery,
        sendingMethod: resDataSelect.sendingMethod.filter(
          (item) =>
            item.name !== 'Калининград (by sea/railway/ferry)' &&
            item.name !== 'СПб'
        ),
        terminal: resDataSelect.finalDestination,
        containerType: resDataSelect.containerType,
        agent: resDataSelect.agent,
        agentContacts: resDataSelect.agentContacts,
        condition: resDataSelect.condition,
        port: resDataSelect.port,
        destination: resDataSelect.destination,
        sendingStation: resDataSelect.sendingStation
          ? resDataSelect.sendingStation
          : [],
        waypoint: resDataSelect.waypoint,
      });
    } catch (error) {
      console.log('error-getDataSelect >>>', error);
    }
  };

  const fetchData = async () => {
    await fetchDataTable();
    await fetchDataSelect();
    postLog('Заявки (reslog)', 'вход в раздел Заявки (reslog)');
  };

  const downloadHandler = async (offerId, docType) => {
    /* 
    хранение информации о категориях товаров без файлов во время попытки загрузки 
    архива доп.док-в, для дальнейшего её отображения в модальном окне  
     */
    const noFileCategoryNames = [];

    try {
      //* в ответ на первый запрос приходит информация о том, какие файлы будут загружены,
      //* а каких не хватает
      const resultStage1 = await request(
        `api/product/dwnld/${offerId}/${docType}/1`
      );

      if (
        resultStage1.message === 'success' ||
        resultStage1.message === 'warning'
      ) {
        //* заполнение массива с категориями продуктов без файлов
        resultStage1.productsWithFiles.forEach((product) => {
          if (!product.additDocsFiles.length) {
            noFileCategoryNames.push(product.categoryName);
          }
        });

        //* в случае, если message в первом запросе равен 'success' (у всех товаров есть файлы)
        //* или 'warning' (у части товаров нет файлов), на сервер отправляется второй запрос (ответ - архив)
        const resultStage2 = await fetch(
          `${process.env.REACT_APP_API_URL}api/product/dwnld/${offerId}/${docType}/2`
        );

        //* если ответ с архивом успешен
        if (resultStage2.ok) {
          const blobData = await resultStage2.blob();
          const url = window.URL.createObjectURL(blobData);
          const a = document.createElement('a');
          const filename = `${docType}_offer_${offerId}.zip`;
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }

        //* случай, когда все файлы есть в БД (и ни одно название категории не попало в noFileCategoryNames)
        if (noFileCategoryNames.length === 0) {
          Modal.success({
            title: 'Успешно',
            content: 'Все файлы загружены',
          });
        } else if (noFileCategoryNames.length > 0) {
          //* случай, когда не все файлы есть в БД (и некоторые названия категорий попали в noFileCategoryNames)
          Modal.warning({
            title: 'Неполная загрузка',
            content: (
              <div>
                <p>Отсутствуют доп. документы категорий:</p>
                <ul>
                  {noFileCategoryNames.map((item) => (
                    <li key={`key-${item}`}>{item}</li>
                  ))}
                </ul>
                <p>Файлы товаров других категорий загружены успешно.</p>
              </div>
            ),
          });
        }
      } else {
        //* случай, когда по данному Offer у товаров отсутствуют доп.документы выбранного типа
        Modal.error({
          title: 'Ошибка',
          content:
            'В продуктах данного оффера отсутствуют файлы доп.документов выбранного типа.',
        });
      }
    } catch (error) {
      Modal.error({
        title: 'Ошибка при запросе к серверу:',
        content: String(error),
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Form onFinish={onFinish}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
          rowClassName={(record) => (record.color ? record.color : '')}
          scroll={{ x: true, y: 'calc(100vh - 340px)' }}
          summary={(pageData) => {
            let totalContainersCount = 0;
            let totalContainersCountBatteries = 0;
            let totalContainersCountOil = 0;
            pageData.forEach(
              ({
                containersCount,
                containersCountBatteries,
                containersCountOil,
              }) => {
                totalContainersCount += +containersCount;
                totalContainersCountBatteries += +containersCountBatteries;
                totalContainersCountOil += +containersCountOil;
              }
            );

            return (
              <Table.Summary.Row>
                <Table.Summary.Cell className="summary" index={0}>
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                <Table.Summary.Cell className="summary" index={5}>
                  {totalContainersCount}
                </Table.Summary.Cell>
                <Table.Summary.Cell className="summary" index={6}>
                  {' '}
                  {totalContainersCountBatteries}
                </Table.Summary.Cell>
                <Table.Summary.Cell className="summary" index={7}>
                  {totalContainersCountOil}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </Form>
      {isModalVisible && (
        <ModalOffer
          dataForSelect={dataForSelect}
          showModal={showModal}
          fetchDataOffers={fetchDataTable}
          fetchDataSelect={fetchDataSelect}
          extraData={extraData}
        />
      )}
      <div className="OrdersTable__footer">
        <Button onClick={handlerMyOffers}>Мои предложения</Button>
      </div>
    </>
  );
};

export default OrdersTable;

import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Modal,
  Input,
  Select,
  Divider,
  Space,
  Typography,
  DatePicker,
  InputNumber,
  message,
  Spin,
  Checkbox,
  notification,
} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ru_RU';
import moment from 'moment';
import { useHttp } from '../../hooks/http.hook';
import './index.css';
import ModalAgent from '../modalAgent/ModalAgent';
import { debounce } from '../../utils/debounce';

import {
  sendingStationLists,
  termsOfDeliveryList,
  terminalLists,
} from './constsForLists'; // константы с элементами меню для Select-списков
import { useLogUser } from '../../hooks/useLogUser.hook';

const ModalOffer = ({
  dataForSelect,
  showModal,
  copyData,
  fetchDataOffers,
  fetchDataSelect,
  extraData,
}) => {
  const { request, loading } = useHttp();
  const { postLog } = useLogUser();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [termsOfDelivery, setTermsOfDelivery] = useState(termsOfDeliveryList);
  const [sendingMethod, setSendingMethod] = useState(
    dataForSelect ? dataForSelect.sendingMethod : []
  );
  const [agentContacts, setAgentContacts] = useState(
    dataForSelect ? dataForSelect.agentContacts : []
  );
  // изначально остается пустым, тк список опций меню используется из константы
  const [terminal, setTerminal] = useState([]);
  const [port] = useState(dataForSelect ? dataForSelect.port : []);
  const [destination] = useState(
    // в меню используется список конечных пунктов с пояснением
    [
      'МСК (до склада в городе Чехов)',
      'НСК (до склада в село Толмачево)',
      'Калининград (до склада в Калининграде, ул. Ялтинская)',
      'КЗ (до станции Медеу/Алматы без автовывоза)',
    ]
  );
  // изначально остается пустым, тк список опций меню используется из константы
  const [sendingStation, setSendingStation] = useState([]);
  const [containerType] = useState(
    dataForSelect ? dataForSelect.containerType : []
  );

  const [rowData] = useState(
    copyData
      ? {
          offerGw: copyData.offerGw,
          offerContainers: copyData.offerContainersCount,
          offerRealContainers: copyData.realContainersCount,
        }
      : extraData?.offerData
  );
  const [selectMethod, setSelectMethod] = useState([]);
  const [isAgentModalVisible, setIsAgentModalVisible] = useState(false);
  const [isSaveTextVisible, setIsSaveTextVisible] = useState(false);
  //  имя выбранного метода доставки (для условной верстки вып списка "Порт/станция отправки")
  // (selectMethod для этого не подходит, в нем не имя)
  const [selectedMethodName, setSelectedMethodName] = useState('');
  // индикатор того, что метод отправки выбран (без него меню "Порт/станция отправки" неактивно)
  const [isMethodChosen, setIsMethodChosen] = useState(copyData ? true : false);
  const [conditionValue, setConditionValue] = useState(
    copyData ? [+copyData?.condition] : []
  );
  const [datesOfCompletionOptions, setDatesOfCompletionOptions] = useState([]); // набор опций для чекбоксов с датами готовности товаров

  // запрос Offer, если мод.окно используется для копирования заявки (таблица "Мои предложения")
  const fetchOffer = async () => {
    // коррекция destination, который будет отправлен на сервер
    let correctDestination;
    if (copyData.destination.includes('МСК')) {
      correctDestination = 'МСК';
    } else if (copyData.destination.includes('НСК')) {
      correctDestination = 'НСК';
    } else if (copyData.destination.includes('КЗ')) {
      correctDestination = 'КЗ(Kazakhstan)';
    } else if (copyData.destination.includes('Калининград')) {
      correctDestination = 'Калининград(Kaliningrad)';
    }
    await request(`api/offer/by-port/${copyData.port}/${correctDestination}`)
      .then((res) => {
        const { exitDateFromChina } = form.getFieldsValue([
          'exitDateFromChina',
        ]);

        // подготовка массива опций для Checkbox.Group с датами готовности товаров
        const options = res?.offer_containers_by_dates
          ?.sort((a, b) => {
            if (!a.date) return 1;
            if (!b.date) return -1;

            return moment(b.date).diff(moment(a.date));
          })
          .map(
            // map сравнивает даты готовности товаров с датой выхода из Китая из copyData
            // и активирует чекбоксы с датами, подходящими под условие (<дата выхода из Китая> - 3 дня)
            (item) => ({
              label: `${
                item?.date
                  ? moment(item.date).format('DD-MM-YYYY')
                  : 'нет данных'
              } - ${item?.containersCount} кнт`,
              value: `${
                item?.date
                  ? moment(item.date).format('DD-MM-YYYY')
                  : 'нет данных'
              }`,
              disabled:
                // условие, при выполнении которого чекбокс активируется
                exitDateFromChina &&
                item?.date &&
                moment(item?.date).isSameOrBefore(
                  exitDateFromChina.clone().subtract(4, 'days'),
                  'day'
                )
                  ? false
                  : true,
            })
          );

        // значения чекбоксов, которые должны стать checked
        const checkedValues = options
          .filter((item) => !item.disabled)
          .map((item) => item.value);

        // чекбоксы с датами, удовлетворяющими условию, становятся checked
        form.setFieldsValue({ datesOfCompletion: checkedValues });

        setDatesOfCompletionOptions(options);
      })
      .catch((error) => {
        console.log('Error in fetchLists:', error);
        message.error('Ошибка получения данных', 5);
      });
  };

  useEffect(() => {
    if (copyData) {
      fetchOffer();
    }
  }, [copyData]);

  // обработка данных для чекбоксов с датами готовности товаров
  useEffect(() => {
    // const options = prepareOptions(
    //   extraData?.datesOfCompletion,
    //   extraData?.lists
    // );
    const options = extraData?.offer_containers_by_dates.map((item) => ({
      label: `${
        item?.date ? moment(item?.date)?.format('DD-MM-YYYY') : 'нет данных'
      } - ${item?.containersCount} кнт`,
      value: `${
        item?.date ? moment(item?.date)?.format('DD-MM-YYYY') : 'нет данных'
      }`,
      disabled: true,
    }));

    setDatesOfCompletionOptions(options);
  }, [extraData]);

  /* 
  первый элемент conditionValue - значение, введённое в поле condition
  ("Условия по льготному  использованию контейнерного  оборудования").
  каждый следующий элемент - значение, вписанное в поле "по..день".
  используется, чтобы автоматич проставить в поле "с..дня" значение (+1)
  */

  // если конечный пункт оффера, на котором открыто мод окно -- "КЗ", то список
  // методов отправки фильтруется (все, кроме СПБ и Калининград)
  useEffect(() => {
    if (extraData?.destination?.includes('КЗ')) {
      setSendingMethod(
        sendingMethod.filter(
          (item) =>
            !['СПБ (by sea)', 'Калининград (by sea)'].includes(item.name)
        )
      );
    }
  }, [extraData]);

  // при наличии элементов-периодов в массиве copyData.excessEquipmentUsagePeriodsPrices
  // эффект добавляет эти периоды в Form.List, заполняет их и обновляет conditionValue
  useEffect(() => {
    if (copyData?.excessEquipmentUsagePeriodsPrices?.length) {
      const periods =
        copyData?.excessEquipmentUsagePeriodsPrices.map((item) => {
          return {
            startDay: item.startDay,
            endDay: item.endDay,
            price: item.price,
          };
        }) || [];
      // добавление периодов из copyData
      form.setFieldsValue({
        excessEquipmentUsagePeriodsPrices: periods,
      });

      // обновление conditionValue (для корректного добавления новых периодов)
      const addConditionValues =
        copyData?.excessEquipmentUsagePeriodsPrices?.map((item) => item.endDay);
      setConditionValue((prev) => [...prev, ...addConditionValues]);
    }
  }, [copyData?.excessEquipmentUsagePeriodsPrices, form]);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const closeModal = () => {
    setIsModalVisible(false);
    showModal(false);
    message.info('Данные записаны!');
  };

  // функция открытия уведомления о некорректном заполнении поля
  const openNotification = (key) => {
    // соотношение ключей формы и русских названий полей
    const keysValues = {
      finalDestination: 'Терминал назначения',
      line: 'Линия',
      riskLevel: 'Возможные трудности при  перевозке',
      sendingStation: 'Порт/станция отправки',
    };

    api.warning({
      message: 'Ошибка!',
      description: (
        <span>
          Поле <b>{keysValues[key]}</b> заполнено некорректно.
        </span>
      ),
    });
  };

  const onFinishModal = async (values) => {
    // перед любыми действиями, значения всех полей проверяются на некорректное заполонение
    // (строка с пробелами "   ")
    for (const key in values) {
      if (typeof values[key] === 'string') {
        const trimmedString = values[key]?.trim();
        // если после удаления пробелов, остается пустая строка, пользователь видит уведомление
        if (!trimmedString?.length) {
          openNotification(key);
          return;
        }
        // если строка не пустая, из значения удаляются пробелы и функция работает дальше
        values[key] = trimmedString;
      }
    }

    // если заявка создается копированием ранее созданной, то из элементов
    // copyData.excessEquipmentUsagePeriodsPrices удаляются ключи id и responseId
    if (copyData) {
      values?.excessEquipmentUsagePeriodsPrices.forEach((item) => {
        delete item.id;
        delete item.responseId;
      });
    }

    // проверка, заполнено ли поле "Ставки за сверхнормативное использование оборудования"
    if (
      values.excessEquipmentUsagePeriodsPrices &&
      values.excessEquipmentUsagePeriodsPrices?.length
    ) {
      if (values.general) {
        let newValues = { ...values, ...rowData };
        // пользователь может указать тип контейнера отличный
        // от типа контейнера в самой заявке
        newValues.containerType = values.containerType;
        try {
          await request(`api/offer/response/create`, 'POST', {
            values: newValues,
          });
          form.resetFields();
          await fetchDataOffers();
          closeModal();
          postLog('Заявки (reslog)', 'создана заявка');
        } catch (error) {
          console.log('ERROR - onFinishModal >>>', error);
          message.error('Ошибка при записи данных!');
        }
      } else {
        message.warning('Добавьте согласие на перевозку генерального груза!');
      }
    } else {
      // если поле "Ставки за сверхнормативное использование оборудования" не заполнено, пользователь видит сообщение
      message.warning(
        'Добавьте период сверхнормативного использования оборудования!'
      );
    }
  };

  const handleModalCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    showModal(false);
  };

  const onChangeNameMethod = (event, isEffect) => {
    // при изменении метода, сбрасываются значения полей "Порт/станция отправки", "Терминал назначения"
    form.resetFields(['sendingStation', 'finalDestination']);

    // если есть copyData, то resetFields вернет к значениям formInit,
    // поэтому добавляем следующую конструкцию для сброса полей "Порт/станция отправ-ки", "Терминал назначения"
    // если не использовать isEffect при первом заходе с copyData поля сразу очистятся
    if (copyData && !isEffect) {
      form.setFields([
        { name: 'sendingStation', value: null },
        { name: 'finalDestination', value: null },
      ]);
    }

    setSelectedMethodName(event); // для условного рендеринга меню "Порт/станция отправки"

    // для метода "КЗ", поле "Цена от терминала до конечного пункта..." устанавливается в 0
    if (event === 'КЗ (direct railway road)') {
      // form.resetFields(['price']);
      form.setFields([{ name: 'price', value: 0 }]);
    }
    /* 
    для всех методов, кроме "Авто" устанавливаются свои списки в меню "Терминал назначения",
    (для "Авто" будет текстовый Input)

    * ВАЖНО! 
    для метода "ЖД (direct railway road)" будет два варианта списка, в зависимости от
    конечного пункта ("НСК" или остальные)
    */

    // для всех методов, кроме "ЖД" и "Авто"
    if (event !== 'Авто' && event !== 'ЖД (direct railway road)') {
      setTerminal(terminalLists[event]);
      // для метода "ЖД"
    } else if (event === 'ЖД (direct railway road)') {
      const dataToUse = extraData || copyData;
      let destinationKey;
      /*
      в константе terminalLists для метода "ЖД" есть два варианта списка:
      ключи "НСК" и "остальные"

      требуемый ключ определяется из extraData или copyData (смотря, что передано в пропсы)
      */
      destinationKey = dataToUse?.destination?.includes('НСК')
        ? 'НСК'
        : 'остальные';

      setTerminal(terminalLists[event][destinationKey]);
    }
    setIsMethodChosen(true); // для "включения" меню "Порт/станция отправки"
    if (
      event.toLowerCase().includes('sea') &&
      event.toLowerCase().includes('railway')
    ) {
      setSelectMethod('all');
    } else if (event.toLowerCase().includes('sea')) {
      setSelectMethod('sea');
      // в состояние записываются данные из константы для отобр в меню "Порт/станция отправки"
      setSendingStation(sendingStationLists.sea);
    } else if (event.toLowerCase().includes('railway')) {
      setSelectMethod('railway');
      // в состояние записываются данные из константы для отобр в меню "Порт/станция отправки"
      setSendingStation(sendingStationLists.railway);
    } else {
      setSelectMethod('other');
    }
    return null;
  };

  const showAgentModal = (value) => {
    setIsAgentModalVisible(value);
  };

  const showSaveText = (value) => {
    setIsSaveTextVisible(value);
  };

  useEffect(() => {
    if (copyData?.sendingMethod) {
      onChangeNameMethod(copyData.sendingMethod, true);
    }
  }, [copyData]);

  useEffect(() => {
    setAgentContacts(dataForSelect.agentContacts);
  }, [dataForSelect.agentContacts]);

  /* 
  пункты в меню "Конечный пункт - место выгрузки" содержат в себе не только
  название конечной точки, но и уточнение.
  (функция использована в initialValues формы)
  */
  const getFinalPointOptionName = (finalPointName) => {
    switch (true) {
      case finalPointName.includes('МСК'):
        return 'МСК (до склада в городе Чехов)';
      case finalPointName.includes('НСК'):
        return 'НСК (до склада в село Толмачево)';
      case finalPointName.toLowerCase().includes('калининград'):
        return 'Калининград (до склада в Калининграде, ул. Ялтинская)';
      case finalPointName.includes('КЗ'):
        return 'КЗ (до станции Медеу/Алматы без автовывоза)';
      default:
        return '';
    }
  };

  // начальные значения в инпутах формы
  const formInit = {
    termsOfDelivery: copyData?.termsOfDelivery,
    agentContactId: copyData?.agentContactId,
    sendingMethod: copyData?.sendingMethod,
    port: copyData ? copyData?.port : extraData?.port,
    sendingStation: copyData?.sendingStation,
    finalDestination: copyData?.finalDestination,
    destination: getFinalPointOptionName(
      copyData ? copyData?.destination : extraData?.destination
    ),
    line: copyData?.line,
    condition: copyData?.condition,
    // excessEquipmentUsagePriceUsd: copyData?.excessEquipmentUsagePriceUsd,
    exitDateFromChina: copyData ? moment(copyData?.exitDateFromChina) : '',
    validityOfBids: copyData
      ? [
          moment(copyData?.validityOfBidsStart),
          moment(copyData?.validityOfBidsEnd),
        ]
      : [],
    containerType: copyData
      ? copyData?.containerType
      : extraData?.offerData?.containerType,
    pricePerFreight: copyData?.pricePerFreight,
    pricePerFreightComment: copyData?.pricePerFreightComment,
    price:
      copyData?.destination?.includes('КЗ') ||
      extraData?.destination?.includes('КЗ')
        ? 0
        : copyData?.price,
    priceComment: copyData?.priceComment,
    riskLevel: copyData?.riskLevel,
    transitTime: copyData
      ? [
          moment(copyData?.transitTime?.split(',')[0]),
          moment(copyData?.transitTime?.split(',')[1]),
        ]
      : [],
    comment: copyData?.comment,
  };

  // обработчик DatePicker "Дата выхода из Китая"
  const exitFromChinaDatePickerHandler = (exitDateFromChina) => {
    // exitDateFromChina - объект с "Датой выхода из Китая"
    const updatedDates = datesOfCompletionOptions.map((optionObj) => ({
      ...optionObj,
      disabled:
        // условие, при выполнении которого чекбокс активируется
        exitDateFromChina &&
        optionObj.value !== 'нет данных' &&
        moment(optionObj.value, 'DD-MM-YYYY').isSameOrBefore(
          exitDateFromChina.clone().subtract(4, 'days'),
          'day'
        )
          ? false
          : true,
    }));

    // значения чекбоксов, которые должны стать checked
    const checkedValues = updatedDates
      .filter((item) => !item.disabled)
      .map((item) => item.value);
    // обновление состояния опций Checkbox.Group (чекбоксы активируются)
    setDatesOfCompletionOptions(updatedDates);

    // чекбоксы с датами, удовлетворяющими условию, становятся checked
    form.setFieldsValue({ datesOfCompletion: checkedValues });
  };

  return (
    <Modal
      title="Отправить предложение"
      open={isModalVisible}
      footer={[]}
      width={700}
      onCancel={handleModalCancel}
      maskClosable={false}
      confirmLoading={loading}
    >
      {contextHolder} {/* для показа notification */}
      {loading && (
        <div className="loadingMessage">
          <Spin />
          <span className="loadingMessage__title">Сохранение данных</span>
          <span className="loadingMessage__text">Не закрывайте страницу!</span>
        </div>
      )}
      {selectedMethodName === 'КЗ (direct railway road)' && (
        <div
          style={{
            color: 'red',
            textAlign: 'center',
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <span>Условия поставки FOR-FOR.</span>
          <span>
            Раскредитация на станции назначения в Казахстане и автовывоз до
            склада НЕ потребуются.
          </span>
        </div>
      )}
      <Form
        {...layout}
        form={form}
        name="modalForm"
        onFinish={onFinishModal}
        initialValues={formInit}
      >
        <Form.Item
          className="Modal__item"
          name="termsOfDelivery"
          label="Условия поставки"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <Select placeholder="выберите из списка">
            {termsOfDelivery?.map((item) => (
              <Option key={item}>{item}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="agentContactId"
          label="Агент"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <Select
            placeholder="выберите из списка"
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space align="center" style={{ padding: '0 8px 4px' }}>
                  <Typography.Link
                    onClick={() => setIsAgentModalVisible(true)}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <PlusOutlined /> Добавить агента
                  </Typography.Link>
                </Space>
              </>
            )}
          >
            {agentContacts?.map((item) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.contact} ({item.agentName})
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="sendingMethod"
          label="Метод отправки"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <Select
            placeholder="выберите из списка"
            onChange={(event) => onChangeNameMethod(event, false)}
          >
            {sendingMethod?.map((item) => (
              <Option key={item?.id} value={item?.name}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="port"
          label="Пункт отправки"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <Select placeholder="выберите из списка">
            {port?.map((item) => (
              <Option key={item.id} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* 
        Form.Item для всех методов, кроме "Авто".
        (с Select)
        * условная верстка Select и Input в один Form.item не работает корректно
        */}
        {selectedMethodName !== 'Авто' && (
          <Form.Item
            className="Modal__item"
            name="sendingStation"
            label="Порт/станция отправки"
            rules={[{ required: true, message: 'Поле не может быть пустым' }]}
          >
            <Select placeholder="выберите из списка" disabled={!isMethodChosen}>
              {selectedMethodName !== 'Авто' &&
                sendingStation.map((item) => (
                  <Option key={item}>{item}</Option>
                ))}
            </Select>
          </Form.Item>
        )}
        {/* 
        Form.Item для "Авто" (текстовый Input)
        */}
        {selectedMethodName === 'Авто' && (
          <Form.Item
            className="Modal__item"
            name="sendingStation"
            label="Порт/станция отправки"
            rules={[{ required: true, message: 'Поле не может быть пустым' }]}
          >
            <Input placeholder="введите название" />
          </Form.Item>
        )}
        {selectedMethodName !== 'Авто' && (
          <Form.Item
            className="Modal__item"
            name="finalDestination"
            label="Терминал назначения"
            rules={[{ required: true, message: 'Поле не может быть пустым' }]}
          >
            <Select placeholder="выберите из списка" disabled={!isMethodChosen}>
              {terminal?.map((item) => (
                <Option key={item}>{item}</Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {selectedMethodName === 'Авто' && (
          <Form.Item
            className="Modal__item"
            name="finalDestination"
            label="Терминал назначения"
            rules={[{ required: true, message: 'Поле не может быть пустым' }]}
          >
            <Input placeholder="введите название" />
          </Form.Item>
        )}
        <Form.Item
          className="Modal__item"
          name="destination"
          label="Конечный пункт - место выгрузки"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <Select placeholder="выберите из списка" disabled>
            {destination?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {(selectMethod === 'sea' || selectMethod === 'all') && (
          <Form.Item
            className="Modal__item"
            name="line"
            label="Линия"
            rules={[{ required: true, message: 'Поле не может быть пустым' }]}
          >
            <Input placeholder="впишите данные" />
          </Form.Item>
        )}
        <Form.Item
          className="Modal__item"
          name="condition"
          label="Условия по льготному  использованию контейнерного  оборудования"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <InputNumber
            placeholder="впишите данные"
            addonAfter="дней"
            onChange={debounce((value) => {
              form.resetFields(['excessEquipmentUsagePeriodsPrices']);
              setConditionValue([value]);
            }, 300)}
          />
        </Form.Item>
        <Form.List name="excessEquipmentUsagePeriodsPrices">
          {(fields, { add, remove }) => (
            <>
              <Form.Item
                label={
                  <span>
                    <span
                      style={{
                        color: 'red',
                        fontSize: '10px',
                        fontWeight: 'lighter',
                      }}
                    >
                      ✽{' '}
                    </span>
                    Ставки за сверхнормативное использование оборудования
                  </span>
                }
              >
                <Button
                  type="dashed"
                  // добавление нового периода в "Ставки за сверхнормативное использование оборудования"
                  onClick={() => {
                    const conditionInputValue = form.getFieldValue('condition'); // значение в поле "Условия по льготному..."
                    // массив значений периодов в "Ставки за..."
                    const currentValues =
                      form.getFieldValue('excessEquipmentUsagePeriodsPrices') ||
                      [];

                    // проверка, заполнены ли все ключи в последнем элементе массива "excessEquipmentUsagePeriodsPrices"
                    const lastEntry = currentValues[currentValues.length - 1];
                    const allKeysFilled = lastEntry
                      ? lastEntry.startDay !== undefined &&
                        lastEntry.endDay !== undefined &&
                        lastEntry.price !== undefined
                      : true;

                    if (conditionInputValue) {
                      // новый период добавляется только при условиях, что заполнены "Условия по льготному..."
                      // и последний период в "Ставки за..."
                      if (allKeysFilled) {
                        // если условия выполнены, в массив conditionValue добавляется undefined
                        // (он изменится на число, когда в соотв Input будут введены данные)
                        setConditionValue((prev) => [...prev, undefined]);
                        add();
                        // для последнего добавленного периода устанавлявается значение в "с..дня"
                        form.setFieldsValue({
                          excessEquipmentUsagePeriodsPrices: [
                            ...currentValues,
                            {
                              startDay:
                                conditionValue[conditionValue.length - 1] + 1,
                            },
                          ],
                        });
                        // если какое-то из условия не выполнено, пользователь видит уведомление
                      } else {
                        message.warning(
                          'Заполните все поля в последнем периоде перед добавлением нового',
                          [5]
                        );
                      }
                    } else {
                      message.warning(
                        'Заполните поле "Условия по льготному использованию контейнерного оборудования"',
                        [5]
                      );
                    }
                  }}
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                  }}
                  icon={<PlusOutlined />}
                  title="Добавить период"
                  danger={!fields.length}
                >
                  Добавить период
                </Button>

                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      width: 'fit-content',
                      marginBottom: '12px',
                    }}
                    key={key}
                  >
                    <span>с</span>
                    <Form.Item
                      {...restField}
                      name={[name, 'startDay']}
                      fieldKey={[fieldKey, 'startDay']}
                    >
                      <InputNumber
                        controls={false}
                        /*
                        в Input устанавливается значение, равное "Условая по льготному..." + 1
                        (если это первый период) или равное "по..день" последнего созданного периода
                        */

                        defaultValue={
                          conditionValue[conditionValue.length - 1] + 1
                        }
                        disabled
                        addonAfter="дня"
                        style={{
                          width: '95px',
                        }}
                      />
                    </Form.Item>
                    <span>по</span>
                    <Form.Item
                      {...restField}
                      name={[name, 'endDay']}
                      fieldKey={[fieldKey, 'endDay']}
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        addonAfter="день"
                        style={{
                          width: '95px',
                        }}
                        // поле "по..день" активно только у последнего созданного периода
                        disabled={fields?.length > index + 1}
                        onChange={debounce((value) => {
                          // ввод данных в поле заменяет последний элемент в массиве conditionValue
                          setConditionValue((prev) => {
                            const curr = [...prev];
                            curr[curr.length - 1] = value;
                            return curr;
                          });
                        }, 300)}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'price']}
                      fieldKey={[fieldKey, 'price']}
                      rules={[
                        {
                          required: true,
                          message: '',
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        addonAfter="$ / сутки"
                        style={{
                          width: '130px',
                        }}
                      />
                    </Form.Item>
                    {index === fields.length - 1 && (
                      <MinusCircleOutlined
                        style={{ position: 'relative', top: '10px' }}
                        onClick={() => {
                          // удаляет последний элемент массива conditionValue (для корректного добавления след периода)
                          setConditionValue((prev) => prev.slice(0, -1));
                          // удаляет период
                          remove(name);
                        }}
                      />
                    )}
                  </div>
                ))}
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          className="Modal__item"
          name="exitDateFromChina"
          label="Дата выхода из Китая"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <DatePicker
            format={'DD-MM-YYYY'}
            locale={locale}
            placeholder="выберите дату"
            onChange={exitFromChinaDatePickerHandler}
          />
        </Form.Item>
        <Form.Item
          className="OrdersModal__item"
          style={{ marginLeft: '34%' }}
          name="datesOfCompletion"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
          label=""
        >
          <Checkbox.Group
            className="OrdersModal__dates-checkboxes"
            options={datesOfCompletionOptions || []}
          />
        </Form.Item>
        {/* 
        уведомление о том, что некоторые кнт не попадут в заявку из-за неподходящей даты выхода из Китая.
        появляется при условии:
        - в форму введена дата выхода из Китая;
        - массив опций с датами для Checkbox.Group не пуст;
        - в массиве опций есть те, у которых ключ disabled === true
          (чекбокс "отключен", тк дата не попадает под даты выхода из Китая)
        */}
        {form.getFieldsValue(['exitDateFromChina'])?.exitDateFromChina &&
        datesOfCompletionOptions?.length &&
        datesOfCompletionOptions?.filter(
          (item) => item?.value !== 'нет данных' && item?.disabled
        )?.length ? (
          <div style={{ color: 'red', marginLeft: '2.5vw' }}>
            Контейнеры с датой готовности{' '}
            <span style={{ fontWeight: 'bold' }}>
              {/*
              из массива опций фильтруются те, которые disabled (дата не подходит),
              и их value собираются в строку через ", "
              */}
              {datesOfCompletionOptions
                .filter((item) => item?.value !== 'нет данных' && item.disabled)
                .map((item) => item.value)
                .join(', ')}
            </span>{' '}
            не попадут в заявку, так как дата выхода из Китая раньше, чем дата
            готовности груза
          </div>
        ) : (
          ''
        )}
        <Form.Item
          className="Modal__item"
          name="validityOfBids"
          label="Валидность ставок"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <RangePicker
            format={'DD-MM-YYYY'}
            locale={locale}
            placeholder={['начальная дата', 'конечная дата']}
          />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="containerType"
          label="Тип КТК"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <Select placeholder="выберите из списка">
            {containerType?.map((item) => (
              <Option key={item?.id} value={item?.name}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="pricePerFreight"
          label="Цена за фрахт ($)"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <InputNumber placeholder="впишите данные" addonAfter="$" />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="pricePerFreightComment"
          label="Комментарий к цене"
        >
          <Input placeholder="впишите данные" />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="price"
          label="Цена от терминала до конечного пункта (т.е. склада) (РУБ)"
          rules={[
            {
              required: true,
              message: 'Поле не может быть пустым',
            },
          ]}
        >
          <InputNumber
            placeholder="впишите данные"
            addonAfter="&#8381;"
            disabled={
              selectedMethodName === 'КЗ (direct railway road)' ||
              copyData?.destination?.includes('КЗ') ||
              extraData?.destination?.includes('КЗ')
            }
          />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="priceComment"
          label="Комментарий к цене"
        >
          <Input placeholder="впишите данные" />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="riskLevel"
          label="Возможные трудности при  перевозке"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <Input placeholder="впишите данные" />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="transitTime"
          label="Транзитное время"
          rules={[{ required: true, message: 'Поле не может быть пустым' }]}
        >
          <RangePicker
            format={'DD-MM-YYYY'}
            locale={locale}
            placeholder={['начальная дата', 'конечная дата']}
          />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="comment"
          label="Комментарий"
          /* 
          если в меню "Порт/станция отправки" или "Терминал назначения" выбрано
          "Другое (обязательно указать в комментарии)", поле "комментарий" становится обязательным
          */
          rules={[
            /* 
            если валидатор видит, что в меню "Порт/станция отправки" и/или "Терминал назначения" выбран вариант "Другое...",
            поле "Комментарий" становится обязательным, и если оно не заполнено, пользователь видит сообщение
            */
            ({ getFieldValue }) => ({
              validator(_, value) {
                // в двух выбрано "Другое..."
                if (
                  getFieldValue('sendingStation') ===
                    'Другое (обязательно указать в комментарии)' &&
                  getFieldValue('finalDestination') ===
                    'Другое (обязательно указать в комментарии)' &&
                  !value
                ) {
                  return Promise.reject(
                    'В меню "Порт/станция отправки" и "Терминал назначения" выбран вариант "Другое (обязательно указать в комментарии)"'
                  );
                }
                // в "Порт/Станция отправки" выбрано "Другое..."
                if (
                  getFieldValue('sendingStation') ===
                    'Другое (обязательно указать в комментарии)' &&
                  !value
                ) {
                  return Promise.reject(
                    'В меню "Порт/станция отправки" выбран вариант "Другое (обязательно указать в комментарии)"'
                  );
                }
                // в "Терминал назначения" выбрано "Другое..."
                if (
                  getFieldValue('finalDestination') ===
                    'Другое (обязательно указать в комментарии)' &&
                  !value
                ) {
                  return Promise.reject(
                    'В меню "Терминал назначения" выбран вариант "Другое (обязательно указать в комментарии)"'
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input placeholder="впишите данные" />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="general"
          valuePropName="checked"
          label="Готовы везти генеральный груз"
          rules={[
            {
              required: true,
              message: 'Необходимо дать согласие везти генеральный груз',
            },
          ]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="oil"
          valuePropName="checked"
          label="Готовы везти масло"
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          className="Modal__item"
          name="batteries"
          valuePropName="checked"
          label="Готовы везти батерейки"
        >
          <Checkbox />
        </Form.Item>

        <Divider style={{ margin: '20px 0' }} />
        {isSaveTextVisible && (
          <span color="red">
            Идет сохранение данных. Не закрывайте страницу
          </span>
        )}
        <div className="Modal__btnsWrapper">
          <Form.Item>
            <Button
              className="Modal__btnSubmit"
              type="primary"
              htmlType="submit"
              disabled={loading}
            >
              OK
            </Button>
          </Form.Item>
        </div>
      </Form>
      {isAgentModalVisible && (
        <ModalAgent
          showModal={showAgentModal}
          // addAgent={addAgent}
          fetchDataSelect={fetchDataSelect}
        />
      )}
    </Modal>
  );
};

export default ModalOffer;
